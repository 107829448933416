import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import moment from 'moment';
import {
  get,
  isEmpty,
  chain,
  isNil,
  isString,
  has,
  set,
  unset,
  isEqual
} from 'lodash';
import Notifier from 'Common/components/Notifier';

import {
  processSeriousnessCriteria,
  generateSubCaseLink,
  getSubcaseProductValue,
  getCountryOfPrimaryReporter,
  getOrElse,
  getAssignedUserName,
  getColumnValueForCSVExport
} from 'Common/utils';
import {
  STATUSES_MAP,
  CC_SUB_CASE_TYPES_MAP,
  CASE_STATUS_NEW,
  DATE_FORMAT,
  API_DATE_FORMAT,
  NOT_SPECIFIED,
  DOCUMENT_TITLE_MAP,
  SCHEMA_PATH_FORM,
  CMS_PROP_TYPES,
  CASE_STATUS_IN_PROGRESS,
  CASE_STATUS_COMPLETED
} from 'Common/constants';
import {
  QUEUE_CASES_TO_REVIEW,
  QUEUE_YOUR_CASES,
  DEFAULT_FILTER_STATE,
  RESULTS_PER_PAGE,
  HEADER_ITEMS_MAP,
  RECORDS_COUNT_DOWNLOAD_CSV,
  RECORDS_COUNT_DOWNLOAD_CSV_ERROR,
  AE_STATE_PATHS,
  PQ_STATE_PATHS,
  EXPORT_TO_CSV_BOOK_NAMES,
  EXPORT_TO_CSV_FILE_NAMES,
  FILTERS_QUEUE_PAGE
} from 'Queue/constants';
import { GENERIC_STATE_PATHS } from 'Search/constants'; // TODO: Move to common?
import Pagination from 'Common/components/Pagination';
import {
  hasAssignedCase,
  getTasksCount,
  getAETasksCount,
  buildDropdownFilterOptions
} from 'Queue/utils';
import withModal, { modalStyles } from 'Common/components/withModal';
import QueueHeader from './QueueHeader';
import Layout from './Layout';
import CustomizeQueueResults from './CustomResults';
import QueueResultsModal from './QueueResultsModal';
import QueueFilterModal from './QueueFilterModal';
import AppliedFilters from './AppliedFilters';

class QueueController extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    totalResults: PropTypes.number,
    actions: PropTypes.shape({
      emitFetchQueue: PropTypes.func.isRequired,
      emitFetchCsvQueue: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      emitSchemaFetch: PropTypes.func.isRequired,
      emitFetchTasksCounts: PropTypes.func.isRequired,
      emitCleanSlate: PropTypes.func.isRequired,
      emitUpdateQueueSortBy: PropTypes.func.isRequired,
      emitUpdateFilter: PropTypes.func.isRequired,
      emitSetUserView: PropTypes.func.isRequired,
      emitFetchUserView: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        userId: PropTypes.string,
        page: PropTypes.string
      }).isRequired
    }).isRequired,
    session: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
    ).isRequired,
    queue: PropTypes.arrayOf(PropTypes.object).isRequired,
    schema: CMS_PROP_TYPES.schema.isRequired, // eslint-disable-line
    tacticalData: CMS_PROP_TYPES.tacticalData,
    tasksCounts: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.shape({}),
    pageNumber: PropTypes.number.isRequired,
    masterCaseUserViewColumns: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.object)
    ),
    inProgressView: PropTypes.bool,
    selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    schema: null,
    totalResults: 0,
    tacticalData: {},
    tasksCounts: [],
    filters: {},
    masterCaseUserViewColumns: {},
    inProgressView: false,
    selectedItemsToAssignOrArchive: []
  };
  constructor(props) {
    super(props);
    this.state = {
      dynamicFilters: this.getFilters(props.match.params.page),
      selectedNotCombinedCases: []
    };
  }

  componentDidMount = async () => {
    const { schema, actions } = this.props;

    if (isEmpty(schema.pages)) {
      await actions.emitSchemaFetch(SCHEMA_PATH_FORM);
    }

    actions.emitCleanSlate();
    this.fetchQueue(this.props);
    this.fetchTasksCounts(this.props);
    this.fetchUserView(this.props);
    // if not first login, display modal and set sessionStorage
    if (!window.localStorage.getItem('nfl') && window.modalMessage) {
      actions.emitModalContentUpdate(
        <div className={`${modalStyles.base} ${modalStyles.modal}`}>
          <div>
            <span className={modalStyles.title}>Notification</span>
            <p style={{ whiteSpace: 'pre-wrap', width: '700px' }}>
              {window.modalMessage}
            </p>
          </div>
        </div>
      );
      window.localStorage.setItem('nfl', 'true');
    }
  };

  componentWillUpdate(nextProps) {
    const { actions } = this.props;
    const { page } = this.props.match.params;
    const nextPage = nextProps.match.params.page;
    const shouldRefetch = nextPage !== page;
    if (shouldRefetch) {
      // leaving the current queue, so clear sessionStorage
      this.clearFilters(null, null, false);
    }

    document.title = DOCUMENT_TITLE_MAP[page];
    if (!shouldRefetch) return;

    actions.emitCleanSlate();
    this.fetchQueue({ ...nextProps, pageNumber: 0 });
    this.fetchTasksCounts(nextProps);
  }

  componentWillUnmount() {
    // clears filters when navigating away from queue
    this.clearFilters(null, null, false);

    this.props.actions.emitCleanSlate();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !isEqual(nextProps, this.props) ||
      !isEqual(nextState, this.state) ||
      !isEqual(nextContext, this.context)
    );
  }

  isSubCaseView = (page = this.props.match.params.page) =>
    isNil(page)
      ? false
      : page === QUEUE_YOUR_CASES ||
        Object.keys(CC_SUB_CASE_TYPES_MAP).includes(page);

  containsSubCaseData = row => {
    const { page } = this.props.match.params;
    const SUB_CASE_PATH = `subcases[${CC_SUB_CASE_TYPES_MAP[page]}]`;
    return !isEmpty(get(row, SUB_CASE_PATH));
  };

  fetchQueue = props => {
    const { actions, match, pageNumber } = props;
    const { page } = match.params;
    const filters = this.getFilters(page);
    const desiredColumns = this.getDesiredColumns();

    actions.emitFetchQueue(
      page,
      filters,
      pageNumber,
      RESULTS_PER_PAGE,
      false,
      desiredColumns
    );
    this.setState({
      dynamicFilters: filters.dynamicFilters
    });
  };

  fetchCSVQueue = props => {
    const { actions, match, pageNumber, filters } = props;
    const { page } = match.params;
    const desiredColumns = this.getDesiredColumns();

    actions
      .emitFetchCsvQueue({
        filters,
        pageType: page,
        pageNumber,
        RECORDS_COUNT_DOWNLOAD_CSV,
        desiredColumns
      })
      .then(data => this.downloadCSVFile(data));
  };

  fetchTasksCounts = propsOrNextProps => {
    const { actions } = propsOrNextProps;
    const { page } = propsOrNextProps.match.params;
    actions.emitFetchTasksCounts(this.isSubCaseView(page));
  };

  fetchUserView = propsOrNextProps => {
    const { actions } = propsOrNextProps;
    actions.emitFetchUserView();
  };

  filterCases = row => {
    const { page } = this.props.match.params;
    const { session } = this.props;

    const FILTER_MAP = {
      ae: this.containsSubCaseData(row),
      mi: this.containsSubCaseData(row),
      pq: this.containsSubCaseData(row),
      undefined: true,
      yours:
        hasAssignedCase(row, session.sub) && row.status !== CASE_STATUS_NEW,
      [QUEUE_CASES_TO_REVIEW]: row.status === CASE_STATUS_NEW
    };

    return FILTER_MAP[page];
  };

  /**
   * takes an array of cases, determines if it contains multiple products
   * if a case contains more than one product then new cases will be created for each product
   * return array of cases where each case has 1 or 0 products in the products array
   */
  getNormalizedProductQueue = filteredQueue => {
    const normalizedProductQueue = [];
    filteredQueue.forEach(caseRecord => {
      if (
        caseRecord.subcases &&
        caseRecord.subcases.productQuality &&
        caseRecord.subcases.productQuality.pqproduct &&
        caseRecord.subcases.productQuality.pqproduct.products &&
        caseRecord.subcases.productQuality.pqproduct.products.length > 1
      ) {
        caseRecord.subcases.productQuality.pqproduct.products.forEach(
          thisProductRecord => {
            const normalizedCaseRecord = {
              ...caseRecord,
              subcases: {
                ...caseRecord.subcases,
                productQuality: {
                  ...caseRecord.subcases.productQuality,
                  pqproduct: {
                    ...caseRecord.subcases.productQuality.pqproduct,
                    products: [thisProductRecord]
                  }
                }
              }
            };
            normalizedProductQueue.push(normalizedCaseRecord);
          }
        );
      } else {
        normalizedProductQueue.push(caseRecord);
      }
    });
    return normalizedProductQueue;
  };

  /**
   * pages are numbered starting with 0
   */
  handlePageChange = pageNumber => {
    const { actions, match: { params }, filters } = this.props;
    actions.emitFetchQueue(params.page, filters, pageNumber, RESULTS_PER_PAGE);
  };

  handleSubcaseFilterChange = key => {
    const { actions, match, filters } = this.props;
    const payload = { ...filters, [key]: !filters[key] };
    this.saveFilters(payload);
    actions.emitFetchQueue(match.params.page, payload);
  };

  handleDropdownFilterChange = (valueOrValues, key) => {
    if (!isNil(valueOrValues)) {
      const { actions, match, filters } = this.props;
      const payload = { ...filters, [key]: valueOrValues };
      actions.emitFetchQueue(match.params.page, payload);
    }
  };

  handleStatusFilterChange = (value, key) => {
    const { actions, match, filters } = this.props;
    const payload = { ...filters, [key]: value };
    this.saveFilters(payload);
    actions.emitFetchQueue(match.params.page, payload);
  };

  handleReconciliationFilterChange = (isReconciliationNeeded, subcaseType) => {
    const { actions, match, filters } = this.props;

    if (isReconciliationNeeded) {
      const allFiltersChecked = {
        nonSubmitted: true,
        submitted: true,
        [CASE_STATUS_NEW]: true,
        [CASE_STATUS_IN_PROGRESS]: true,
        [CASE_STATUS_COMPLETED]: true
      };
      const payload = {
        ...filters,
        ...allFiltersChecked,
        reconciliationNeeded: subcaseType
      };
      this.saveFilters(payload);
      actions.emitFetchQueue(match.params.page, payload);
    } else {
      const defaultStatuses = {
        nonSubmitted: true,
        submitted: false,
        [CASE_STATUS_NEW]: true,
        [CASE_STATUS_IN_PROGRESS]: true,
        [CASE_STATUS_COMPLETED]: false
      };
      const payload = {
        ...filters,
        ...defaultStatuses
      };
      delete payload.reconciliationNeeded;
      this.saveFilters(payload);
      actions.emitFetchQueue(match.params.page, payload);
    }
  };

  handleTextInputChange = (value, filterType) => {
    const { actions, match } = this.props;
    let { filters } = this.props;
    if (Object.keys(this.state.dynamicFilters).length > 0) {
      filters = { ...filters, dynamicFilters: this.state.dynamicFilters };
    }
    const payload = { ...filters, [filterType]: value };
    actions.emitUpdateFilter(match.params.page, payload);
  };

  handleTextInputBlur = (value, filterType) => {
    const { actions, match, filters } = this.props;
    const payload = { ...filters, [filterType]: value };
    actions.emitFetchQueue(match.params.page, payload);
  };

  hasStringData = input => !isNil(input) && isString(input) && input.length > 0;

  downloadCSVFile(data) {
    try {
      const { searchCase } = data;
      const { hits } = searchCase || { hits: [] };
      const queue = hits || [];
      const { page } = this.props.match.params;
      const { tacticalData } = this.props;
      const filteredQueue = (page === QUEUE_YOUR_CASES
        ? this.processSubCases(queue).filter(row => row.status !== 'Archived')
        : queue
      )
        .filter(this.filterCases)
        .map(this.addCountry);
      let columns = this.getDesiredColumns();
      const records = [];
      const ColumnstoDisplay = [];
      columns = columns.filter(x => x.label !== 'Actions');
      columns.map(col => ColumnstoDisplay.push(col.label));
      records.push(ColumnstoDisplay);

      const queueType = this.props.match.params.page;
      const caseQueue =
        queueType !== 'pq'
          ? filteredQueue
          : this.getNormalizedProductQueue(filteredQueue);

      caseQueue.forEach(rec => {
        const record = [];
        columns.forEach(col => {
          let recValue = getColumnValueForCSVExport(
            rec,
            col.label,
            page,
            tacticalData
          );
          recValue = recValue || ' ';
          record.push(recValue);
        });
        records.push(record);
      });

      const ws = XLSX.utils.aoa_to_sheet(records);
      const wb = XLSX.utils.book_new();
      const bookName = EXPORT_TO_CSV_BOOK_NAMES[page];
      XLSX.utils.book_append_sheet(wb, ws, bookName);
      /* generate XLSX file and send to client */
      const date = new Date();
      const getHour = (date.getHours() < 10 ? '0' : '') + date.getHours();
      const getMinutes =
        (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
      const currentHours = getHour.toString() + getMinutes.toString();
      const getMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1);
      const getDate = (date.getDate() < 10 ? '0' : '') + date.getDate();
      const currentDate =
        getMonth.toString() +
        getDate.toString() +
        date.getFullYear().toString();
      const fileTitle = EXPORT_TO_CSV_FILE_NAMES[page];
      const fileName = `${fileTitle}_${currentHours}-${currentDate}.xlsx`;
      XLSX.writeFile(wb, fileName);
    } catch (ex) {
      console.log('logging exception details,', ex.message);
    }
  }

  handleExportToCSV = () => {
    const { totalResults } = this.props;
    if (RECORDS_COUNT_DOWNLOAD_CSV < totalResults) {
      Notifier.show({
        message: RECORDS_COUNT_DOWNLOAD_CSV_ERROR,
        intent: Notifier.PRIMARY
      });
    } else {
      this.fetchCSVQueue(this.props);
    }
  };

  handleSortChange = (sortPath, sortDirection) => {
    const { actions, match: { params }, pageNumber, filters } = this.props;
    actions.emitUpdateQueueSortBy(sortPath, sortDirection);
    actions.emitFetchQueue(params.page, filters, pageNumber, RESULTS_PER_PAGE);
  };

  addCountry = row => {
    const { tacticalData } = this.props;

    return {
      ...row,
      countryOfPrimaryReporterLabel: getCountryOfPrimaryReporter(
        tacticalData,
        row,
        NOT_SPECIFIED
      )
    };
  };

  generateMasterCaseResult = masterCase => {
    const columnData = {
      id: masterCase.id,
      seriousnessValue: processSeriousnessCriteria(masterCase)
    };

    const subcases = chain(masterCase.subcases)
      .map((subcase, subCaseKey) =>
        this.generateSubcaseResult(masterCase, subCaseKey, columnData)
      )
      .sortBy(c => c.id) // Sort Subcases
      .value();

    return [columnData].concat(subcases);
  };

  generateSubcaseResult = (masterCase, subcaseKey, columnData) => {
    const { tacticalData, tasksCounts, match } = this.props;
    const id = get(masterCase, `subcases.${subcaseKey}.id`);
    const assignee = get(
      masterCase,
      `subcases.${subcaseKey}.assignee`,
      'Unassigned'
    );
    const awarenessDate =
      subcaseKey === CC_SUB_CASE_TYPES_MAP.ae
        ? get(masterCase, GENERIC_STATE_PATHS.AE_LRD, NOT_SPECIFIED)
        : get(masterCase, GENERIC_STATE_PATHS.AWARENESS_DATE, NOT_SPECIFIED);
    const tasksCount =
      subcaseKey === CC_SUB_CASE_TYPES_MAP.ae &&
      this.isSubCaseView(match.params.page)
        ? getAETasksCount(tasksCounts, masterCase.id)
        : getTasksCount(tasksCounts, id);

    return {
      ...masterCase,
      ...columnData,
      id,
      assignee,
      caseLink: generateSubCaseLink(masterCase, subcaseKey),
      product: getSubcaseProductValue(
        masterCase,
        tacticalData,
        subcaseKey,
        NOT_SPECIFIED
      ),
      awarenessDate:
        awarenessDate === NOT_SPECIFIED
          ? awarenessDate
          : moment(awarenessDate, API_DATE_FORMAT).format(DATE_FORMAT),
      status:
        STATUSES_MAP[get(masterCase, `subcases.${subcaseKey}.status`, 'NEW')],
      tasksCount,
      subcaseKey
    };
  };

  processSubCases = queue =>
    chain(queue)
      .flatMap(this.generateMasterCaseResult)
      .map()
      .value();

  handleSelectAllCasesOrTasks = evt => {
    const { queue, actions } = this.props;
    const { page } = this.props.match.params;
    if (evt) {
      const filteredQueue = (page === QUEUE_YOUR_CASES
        ? this.processSubCases(queue).filter(row => row.status !== 'Archived')
        : queue
      )
        .filter(this.filterCases)
        .map(this.addCountry);
      // const selectedItems = filteredQueue
      //   ? filteredQueue.map(row => row.id)
      //   : [];
      let selectedItems;
      if (page === 'ae') {
        selectedItems = filteredQueue
          ? filteredQueue.map(row => getOrElse(row, AE_STATE_PATHS.ID))
          : [];
      } else if (page === 'pq') {
        selectedItems = filteredQueue
          ? filteredQueue.map(row => getOrElse(row, PQ_STATE_PATHS.ID))
          : [];
      } else {
        selectedItems = filteredQueue ? filteredQueue.map(row => row.id) : [];
      }
      actions.emitUpdateQueueCaseSelection(selectedItems, true);
      return;
    }
    actions.emitUpdateQueueCaseSelection([], false);
  };

  bulkArchiveCase = (archiveReason, archiveComments) => {
    const {
      selectedItemsToAssignOrArchive,
      actions,
      match,
      tacticalData,
      session
    } = this.props;
    const { selectedNotCombinedCases } = this.state;
    const numberOfCombined =
      selectedItemsToAssignOrArchive.length - selectedNotCombinedCases.length;
    const { page } = match.params;
    const assigneeId = session.sub;
    const assigneeName = getAssignedUserName(
      getOrElse(tacticalData, 'document-data.user-list', []),
      assigneeId,
      session
    );
    actions.emitUpdateBulkArchive(
      'BULK_ARCHIVAL',
      archiveComments,
      archiveReason,
      selectedNotCombinedCases,
      page,
      assigneeId,
      assigneeName
    );
    this.setState({ selectedNotCombinedCases: [] });
    if (numberOfCombined > 0) {
      Notifier.show({
        message: `${numberOfCombined} case${
          numberOfCombined > 1 ? 's' : ''
        } could not be archived`,
        iconName: 'error',
        intent: Notifier.DANGER
      });
    }
  };

  handleSelectCaseOrTask = (selectedCaseId, evt, isCombined) => {
    const { actions, selectedItemsToAssignOrArchive } = this.props;
    let updatedSelectedCaseIds;
    if (evt) {
      if (!isCombined) {
        this.setState({
          selectedNotCombinedCases: this.state.selectedNotCombinedCases.concat(
            selectedCaseId
          )
        });
      }
      updatedSelectedCaseIds = selectedItemsToAssignOrArchive.concat(
        selectedCaseId
      );
      actions.emitUpdateQueueCaseSelection(updatedSelectedCaseIds, false);
      return;
    }
    if (!isCombined) {
      this.setState({
        selectedNotCombinedCases: this.state.selectedNotCombinedCases.filter(
          caseId => caseId !== selectedCaseId
        )
      });
    }
    updatedSelectedCaseIds = selectedItemsToAssignOrArchive.filter(
      caseId => caseId !== selectedCaseId
    );
    actions.emitUpdateQueueCaseSelection(updatedSelectedCaseIds, false);
  };

  assignCaseOrTask = assigneeId => {
    const {
      selectedItemsToAssignOrArchive,
      session,
      tacticalData,
      actions
    } = this.props;
    const userId = assigneeId || session.sub;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const assigneeName = getAssignedUserName(users, userId, session);
    const { page } = this.props.match.params;
    actions.emitUpdateAssignCaseToUser(
      'BULK_ASSIGNMENT',
      userId,
      assigneeName,
      selectedItemsToAssignOrArchive,
      page
    );
  };

  openQueueModal = modalOption => {
    const { queue, tacticalData, actions } = this.props;
    const { page } = this.props.match.params;
    const desiredColumns = this.getDesiredColumns();
    const filteredQueue = (page === QUEUE_YOUR_CASES
      ? this.processSubCases(queue).filter(row => row.status !== 'Archived')
      : queue
    )
      .filter(this.filterCases)
      .map(this.addCountry);
    let pageName;
    if (!page) {
      pageName = 'mc';
    } else if (page === 'new') {
      pageName = 'masterCase';
    } else {
      pageName = page;
    }

    if (modalOption === 'editColumns') {
      actions.emitModalContentUpdate(
        <QueueResultsModal
          onCloseModifyView={this.onCloseModifyView}
          closeModal={actions.emitModalContentClear}
          columns={HEADER_ITEMS_MAP[page]}
          userDesiredColumns={desiredColumns}
          filteredQueue={filteredQueue}
          pageName={pageName}
        />
      );
    } else if (modalOption === 'showFilters') {
      actions.emitModalContentUpdate(
        <QueueFilterModal
          onCloseupdateFilter={this.onCloseupdateFilter}
          closeModal={actions.emitModalContentClear}
          title={FILTERS_QUEUE_PAGE}
          columns={HEADER_ITEMS_MAP[page]}
          userDesiredColumns={desiredColumns}
          filteredQueue={filteredQueue}
          pageName={pageName}
          tacticalData={tacticalData}
          filters={this.props.filters}
          dynamicFilters={this.state.dynamicFilters}
        />
      );
    }
  };

  onCloseupdateFilter = updatedFilters => {
    const { actions, match: { params }, filters } = this.props;
    filters.dynamicFilters = updatedFilters.internalFilter;
    actions.emitFetchQueue(params.page, filters);
    this.saveFilters(filters);
    this.setState({
      dynamicFilters: updatedFilters.internalFilter
    });
  };

  onCloseModifyView = (desiredColumns = [], page) => {
    const { masterCaseUserViewColumns } = this.props;
    const { dynamicFilters } = this.state;
    const newDesiredColumns = {
      [page]: desiredColumns
    };
    const masterviewColumns = Object.assign({}, masterCaseUserViewColumns);
    delete masterviewColumns.inProgress;
    const totalDesiredColumns = Object.assign(
      {},
      masterviewColumns,
      newDesiredColumns
    );
    let pageName;
    if (!page) {
      pageName = 'mc';
    } else if (page === 'new') {
      pageName = 'masterCase';
    } else {
      pageName = page;
    }

    let dynamicFilterApplied = {};
    if (Object.keys(dynamicFilters).length > 0) {
      totalDesiredColumns[pageName].forEach(rec => {
        if (rec.isFilterRequired) {
          if (has(dynamicFilters, rec.FilterOptions.entityPathName)) {
            const selectedValue = get(
              dynamicFilters,
              rec.FilterOptions.entityPathName,
              null
            );
            dynamicFilterApplied = set(
              dynamicFilterApplied,
              rec.FilterOptions.entityPathName,
              selectedValue
            );
          }
        }
      });
    }

    this.setState(() => {
      this.props.actions.emitSetUserView(totalDesiredColumns);
      this.onCloseupdateFilter({ internalFilter: dynamicFilterApplied });
    });
  };

  getDesiredColumns = () => {
    const { masterCaseUserViewColumns } = this.props;
    const { page } = this.props.match.params;
    let pageName;
    // WHYYYYYYYYYYYYY
    if (!page) {
      pageName = 'mc';
    } else if (page === 'new') {
      pageName = 'masterCase';
    } else {
      pageName = page;
    }
    let desiredColumns = [];
    if (
      masterCaseUserViewColumns[pageName] &&
      masterCaseUserViewColumns[pageName].length > 0
    ) {
      desiredColumns = masterCaseUserViewColumns[pageName];
    } else {
      desiredColumns = HEADER_ITEMS_MAP[page].filter(x => x.isMandatoryColumn);
    }
    return desiredColumns;
  };

  getFilters = page => {
    const { session } = this.props;
    const filtersFromStorage = window.sessionStorage.getItem(
      `filters-${page || 'master'}`
    );
    const filters = filtersFromStorage
      ? JSON.parse(filtersFromStorage)
      : DEFAULT_FILTER_STATE;
    if (!filtersFromStorage) {
      if (page === QUEUE_CASES_TO_REVIEW) {
        filters.IN_PROGRESS = false;
        filters.NEW = true;
      } else if (page === QUEUE_YOUR_CASES) {
        filters.IN_PROGRESS = true;
        filters.NEW = false;
        filters.ASSIGNEE = session.sub;
      } else {
        filters.IN_PROGRESS = true;
        filters.NEW = true;
      }
    }
    return filters;
  };
  saveFilters = filters => {
    if (isEmpty(filters)) {
      window.sessionStorage.removeItem(
        `filters-${this.props.match.params.page || 'master'}`
      );
    } else {
      window.sessionStorage.setItem(
        `filters-${this.props.match.params.page || 'master'}`,
        JSON.stringify(filters)
      );
    }
  };
  clearFilters = (path, arrValue, refetch = true) => {
    const { actions, match: { params: { page } } } = this.props;
    let dynamicFilters = { ...this.state.dynamicFilters };
    let { filters } = this.props;
    if (!path) {
      // no path specified, so clear all filters
      this.saveFilters({});
      filters = this.getFilters(page);
      dynamicFilters = {};
    } else if (arrValue) {
      // if supplied, find value in array and remove
      const arr = get(dynamicFilters, path);
      arr.splice(arr.indexOf(arrValue), 1);
      if (arr.length === 0) {
        unset(dynamicFilters, path);
      } else {
        set(dynamicFilters, path, arr);
      }
    } else {
      // normal path object, just unset
      unset(dynamicFilters, path);
    }
    filters.dynamicFilters = dynamicFilters;
    this.saveFilters(filters);
    this.setState({ dynamicFilters });

    // by default, makes a backend call to reload queue
    if (refetch) {
      actions.emitFetchQueue(page, filters);
    }
  };

  render() {
    const {
      queue,
      totalResults,
      isLoading,
      inProgressView,
      pageNumber,
      tacticalData
      // masterCaseUserViewColumns
    } = this.props;
    const { page } = this.props.match.params;

    // Process Tactical Data into dropdown options
    const dropdownFilters = buildDropdownFilterOptions(tacticalData, page);

    const filteredQueue = (page === QUEUE_YOUR_CASES
      ? this.processSubCases(queue).filter(row => row.status !== 'Archived') // We show both sub cases as individual,when one of them is archived its displaying both.To avoid this filtering status to archive
      : queue
    )
      .filter(this.filterCases)
      .map(this.addCountry);

    const queueType = this.props.match.params.page;
    const caseQueue =
      queueType !== 'pq'
        ? filteredQueue
        : this.getNormalizedProductQueue(filteredQueue);

    const desiredColumns = this.getDesiredColumns();
    return (
      <div>
        <QueueHeader
          {...this.props}
          dropdownFilters={dropdownFilters}
          isSubCaseView={this.isSubCaseView()}
          handleStatusFilterChange={this.handleStatusFilterChange}
          handleReconciliationFilterChange={
            this.handleReconciliationFilterChange
          }
          handleDropdownFilterChange={this.handleDropdownFilterChange}
          handleSubcaseFilterChange={this.handleSubcaseFilterChange}
          handleTextInputChange={this.handleTextInputChange}
          handleTextInputBlur={this.handleTextInputBlur}
          handleExportToCSV={this.handleExportToCSV}
          disabled={isLoading || totalResults === 0}
          assignCaseOrTask={this.assignCaseOrTask}
          bulkArchiveCase={this.bulkArchiveCase}
          customizeQueueResults={
            <CustomizeQueueResults
              openQueueModal={this.openQueueModal}
              disabled={isLoading}
            />
          }
          appliedFilters={
            <AppliedFilters
              appliedFilters={this.state.dynamicFilters}
              desiredColumns={desiredColumns}
              tacticalData={tacticalData}
              clearFilters={this.clearFilters}
            />
          }
        />
        <Layout
          {...this.props}
          filteredQueue={caseQueue}
          userDesiredColumns={desiredColumns}
          standardColumns={HEADER_ITEMS_MAP[page]}
          handleSortChange={this.handleSortChange}
          handleSelectAllCasesOrTasks={this.handleSelectAllCasesOrTasks}
          handleSelectCaseOrTask={this.handleSelectCaseOrTask}
          inProgressView={inProgressView || false}
        />
        <Pagination
          totalPages={Math.ceil(totalResults / RESULTS_PER_PAGE)}
          onPageChange={this.handlePageChange}
          disabled={isLoading || totalResults === 0}
          pageOffset={pageNumber}
          dynamicFilters={this.state.dynamicFilters}
        />
      </div>
    );
  }
}

export default withModal(QueueController);
